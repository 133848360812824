.error-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
}

.error-content {
  max-width: 430px;
  text-align: center;
  padding: 40px;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.error-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #ff6347;
  line-height: 34px;
}

.error-description {
  font-size: 18px;
  margin-bottom: 30px;
  color: #333;
}

.error-code {
  font-size: 18px;
  margin-bottom: 30px;
  color: #666;
}

.error-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.refresh-button,
.home-button {
  padding: 0 20px;
  font-size: 18px;
  height: 40px;
  height: 45px;
  vertical-align: middle;
}

.refresh-button {
  background-color: #428bca;
  color: #fff;
}

.home-button {
  background-color: #ccc;
  color: #333;
}
