* {
  box-sizing: border-box;
  font-family: var(--main-font);
}

:root {
  --primary: rgba(0, 0, 0, 0.07);
  --primarylight: rgba(0, 0, 0, 0.05);
  --primarylighthover: rgba(0, 0, 0, 0.06);
  --primarydarkhover: rgba(0, 0, 0, 0.85);
  --primary-contrast: white;
  --secondary: rgba(0, 0, 0, 0.5);
  --secondary-contrast: white;
  --error: #e52854;
  --borderRadius: 3px;
  --success: rgba(0, 128, 0);
  --main-font: 'Open Sans', sans-serif;
  --second-font: 'Open Sans', sans-serif;

  --theme-contrast: 'black';
}

body {
  font-family: var(--main-font);
  font-size: 15px;
  line-height: 1.6em;
  background-color: #efefef;
}

a {
  color: inherit;
  text-decoration: none;
}

main {
  display: flex;
  padding: 0 0 5rem;
  justify-content: center;
  position: relative;
  min-height: 50%;
  @media (max-width: 997px) {
    padding-bottom: 3rem;
  }

  .error-message {
    padding-top: 1rem;
    display: block;
    text-align: center;
  }
}

h2 {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.4em;
  color: var(--primary);
  margin-bottom: 0.4rem;
  font-family: var(--second-font);
  @media (max-width: 767px) {
    font-size: 6vw;
    line-height: 1.4em;
    width: 80%;
    margin-bottom: 3vw;
  }
}

h3 {
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.4em;
  color: var(--primary);
  margin-bottom: 0.1rem;
  position: relative;
  @media (max-width: 767px) {
    font-size: 1.6rem;
    line-height: 1.4em;
  }
}

.h3--big-color {
  padding: 0 0px 5px;
  text-align: left;
  width: 65%;
  margin-bottom: 0.5rem;
  @media (max-width: 767px) {
    width: 100%;
    font-size: 7vw;
    line-height: 1.4em;
  }
}

.h3--contrast {
  width: 100%;
  margin-bottom: 30px;
  color: var(--primary-contrast);
}

legend {
  display: block;
  padding: 1rem 0 1.5rem;
  width: 100%;
  font-size: 0.9rem;
  line-height: 1.4em;
  color: rgba(0, 0, 0, 0.5);
}

button {
  font-family: var(--main-font);
  outline: none;
  border: none;
  background: transparent;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  font-weight: 700;
  border-radius: var(--borderRadius);
  font-size: 14px;
  line-height: 2.6em;
}

.separate-1 {
  margin-top: 0.6rem;
}

.separate-2 {
  margin-top: 1.2rem;
}

.separate-3 {
  margin-top: 2.4rem;
}

.separate-after-3 {
  margin-bottom: 2.4rem;
}

.label-without-checkbox {
  padding-left: 2.3rem;
}

.rate-bundle-term {
  .contract-volume-header,
  .average-price-term,
  .average-price-month {
    color: rgba(0, 0, 0, 0.5);
  }
}

@keyframes appear {
  from {
    opacity: auto;
    visibility: auto;
    transform: auto;
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

/****************************************************************/
/*************************** FONTS ******************************/
/****************************************************************/

@font-face {
  font-family: 'Open Sans Regular';
  src: url('/assets/fonts/OpenSans/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'Open Sans SemiBold';
  src: url('/assets/fonts/OpenSans/OpenSans-SemiBold.ttf');
}

@font-face {
  font-family: 'Open Sans Bold';
  src: url('/assets/fonts/OpenSans/OpenSans-Bold.ttf');
}

@font-face {
  font-family: 'Open Sans ExtraBold';
  src: url('/assets/fonts/OpenSans/OpenSans-ExtraBold.ttf');
}

@font-face {
  font-family: 'Open Sans Light';
  src: url('/assets/fonts/OpenSans/OpenSans-Light.ttf');
}

@font-face {
  font-family: 'Open Sans Light';
  src: url('/assets/fonts/OpenSans/OpenSans-Light.ttf');
}

@font-face {
  font-family: 'Open Sans Medium';
  src: url('/assets/fonts/OpenSans/OpenSans-Medium.ttf');
}

@font-face {
  font-family: 'Open Sans Italic';
  src: url('/assets/fonts/OpenSans/OpenSans-Italic.ttf');
}

@font-face {
  font-family: 'Open Sans BoldItalic';
  src: url('/assets/fonts/OpenSans/OpenSans-BoldItalic.ttf');
}

@font-face {
  font-family: 'Open Sans ExtraBoldItalic';
  src: url('/assets/fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf');
}

@font-face {
  font-family: 'Open Sans LightItalic';
  src: url('/assets/fonts/OpenSans/OpenSans-LightItalic.ttf');
}

@font-face {
  font-family: 'Open Sans MediumItalic';
  src: url('/assets/fonts/OpenSans/OpenSans-MediumItalic.ttf');
}

@font-face {
  font-family: 'Open Sans SemiBoldItalic';
  src: url('/assets/fonts/OpenSans/OpenSans-SemiBoldItalic.ttf');
}
