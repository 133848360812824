@keyframes load {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
}

.loader--regular {
  width: 2rem;
  height: 2rem;
}

.loader--fullpage {
  position: fixed;
  z-index: 100;
  width: 4rem;
  height: 4rem;
  left: calc(50% - 2rem);
  top: 35vh;
}

.loader--contain {
  position: absolute;
  left: 5%;
  top: 5%;
  width: 90%;
  height: 90%;
}

.loader--color-grey {
  color: #b2b2b2;
}

.loader--color-inherit {
  color: inherit;
}

.loader__inside {
  animation: load 0.5s ease-in-out infinite;
  height: 100%;
  max-width: 100%;
}

.loader__svg {
  max-height: 100%;
  max-width: 100%;
}
